.site-footer {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    margin: inherit;

    svg {
      width: auto;
      max-width: 50%;
      height: auto;
      transition: height .3s ease;
      will-change: height;

      path {
        fill: #{$white} !important;
      }
    }

    @include media-breakpoint-up($desktop-breakpoint) {
      max-width: 30%;
    }
  }

  .informations {
    display: none;
  }
}
