.featured-image {
  //CloudFlare video
  .ratio {
    top: -2%;
  }
  @media (orientation: portrait) {
    .ratio {
      height: 100%;

      iframe {
        left: 50%;
        width: calc(var(--featured-image-height) / .5225);
        margin-left: calc(-1 * var(--featured-image-height) / .5225 / 2);
      }
    }
  }

  @include media-breakpoint-between(lg, xxl) {
    .ratio {
      height: 100%;

      iframe {
        left: 50%;
        width: calc(var(--featured-image-height) / .5225);
        margin-left: calc(-1 * var(--featured-image-height) / .5225 / 2);
      }
    }
  }
}
