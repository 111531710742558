.wpcf7 {
  .wpcf7-form {
    .form-field {
      select.wpcf7-select{
        width: 100%;
        height: 2.5em;
        background: none;
      }
    }
  }
}
