@import "config/grid.yaml";
@import "config/templates.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/main";

// === COMPONENTS
@import "components/links";
@import "components/featured-image";
@import "components/forms";

// === LAYOUT
@import "layout/header";
@import "layout/footer";
